import './App.scss';
import React, { Suspense, useState } from 'react';
import Header from './layout/Header/Header';
import { BrowserRouter, Switch, Route, } from "react-router-dom";
import routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';

function App(props) {
  const [isParentData, setIsParentData] = useState(1)
  return (
    <div className="App">
      <Suspense fallback={<></>}>
        <BrowserRouter>
          <Header sendToParent={setIsParentData} />
          <Switch>
            {routes.map((item, index) => {
              return <Route
                key={index}
                exact={item.exact}
                path={item.path}
                component={item.component}
              />
            })}
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;

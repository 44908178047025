import { lazy } from 'react';

const Home = lazy(() =>
    import(/* webpackChunkName: "home" */ /* webpackPreload: true */ "./pages/Home"));

const Faq = lazy(() =>
    import(/* webpackChunkName: "faq" */ /* webpackPreload: true */ "./pages/Faq"));


const routes = [
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/faq",
        component: Faq,
        exact: true
    },
]
export default routes





import React, { useEffect, useState } from "react";
import { Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/images/logo.svg';
import "./Header.scss";

const Header = (props) => {
  const [activeSection, setActiveSection] = useState("Home");
  const [checkFaq, setCheckFaq] = useState(false);
  useEffect(() => {
    window.localStorage.setItem('selectedPage', 0)
    window.localStorage.setItem('activeSession', 1)
  }, [])
  useEffect(() => {
    window.addEventListener('storage', () => {
      console.log("hgere");
      window.localStorage.getItem('activeSession') === "Pricing" ? setActiveSection("Pricing") : window.localStorage.getItem('activeSession') === "Contact" ?
        setActiveSection("Contact") : setActiveSection("Home")
    })
  }, [])
  // useEffect(() => {
  //   console.log("hgere");
  //   window.localStorage.getItem('selectedPage') === 8 ? setActiveSection("Pricing") : window.localStorage.getItem('selectedPage') === 9 ?
  //     setActiveSection("Contact") : setActiveSection("Home")
  // }, [window.localStorage.getItem('selectedPage')])
  return (
    <div className="header">
      <div className="container">
        <Navbar>
          <Navbar.Brand href="/" className="logo" >
            <img src={logo} alt="SmartFinDoc - Logo" title="SmartFinDoc - Logo" width="240" height="51" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav id="menu">
              <li data-menuanchor="home" className="active">
                <a href="/#home">Home</a>
              </li>
              <li data-menuanchor="pricing" className=""><a href="/#pricing">Pricing</a></li>
              <li data-menuanchor="contact" className=""><a href="/#contact">Contact us</a></li>
            </Nav>
          </Navbar.Collapse>
        </Navbar >
      </div>
    </div>
  );
};
export default Header;
